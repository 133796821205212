export default function SectionMark({ variant = "primary", center = false, children, className }) {
  return (
    <div className={`${className} flex gap-2 items-center ${center ? "justify-center" : ""}`}>
      {variant === "primary" ? (
        <>
          <div className="w-7 h-[0px] border-2 border-primary"></div>
          <div className="text-primary text-sm font-bold tracking-wide">{children}</div>
        </>
      ) : (
        <>
          <div className="w-7 h-[0px] border-2 border-white"></div>
          <div className="text-white text-sm font-bold tracking-wide">{children}</div>
        </>
      )}
    </div>
  );
}
