import Link from "next/link";
import { FaDesktop, FaWhatsapp } from "react-icons/fa6";
import Image from "next/image";

export default function CallToAction({ backgroundImage = false }) {
  return (
    <section className="text-dark-muted mx-auto max-full h-[450px] px-4 relative">
      {backgroundImage && (
        <div className="absolute inset-0">
          <Image src="/image/media/dailysocial.jpeg" layout="fill" objectFit="cover" className="opacity-40" />
        </div>
      )}
      <div className="relative z-10 text-center flex justify-center items-center flex-col h-full w-full">
        <h1 className="lg:text-[32px] text-[24px] font-bold tracking-wide">Tunggu Apa Lagi?</h1>
        <div className="flex justify-center">
          <p className="text-opacity-80 tracking-tight sm:max-w-md lg:text-[20px] text-[14px]">
            Jadikan lingkungan sekolah anda menjadi lebih digital dengan memakai <span className="text-primary font-bold">Cards</span>.
          </p>
        </div>
        <div className="flex sm:flex-row flex-col justify-center items-center lg:gap-2 gap-4 lg:mt-12 mt-8">
          <a
            href="https://api.whatsapp.com/send/?phone=62811255170&text&type=phone_number&app_absent=0"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary text-white rounded-full w-[240px] !font-semibold shadow-lg shadow-tprimary-3/70 text-[20px]"
          >
            Kontak Whatsapp
          </a>
          <a href="https://www.jotform.com/240072160338447" target="_blank" rel="noopener noreferrer" className="btn btn-accent text-white rounded-full w-[240px] !font-semibold shadow-lg shadow-accent/70 text-[20px]">
            Jadwalkan Demo
          </a>
        </div>
      </div>
    </section>
  );
}
