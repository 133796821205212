import SectionMark from "../Misc/SectionMark";
import { CgQr } from "react-icons/cg";
import Image from "next/image";
import { GoShieldCheck } from "react-icons/go";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper/modules";

export default function Features() {
  return (
    <div className="mx-auto 3xl:w-[85%] lg:w-[80%] px-4 pt-16">
      <section id="features" className="grid my-16 grid-cols-1 md:grid-cols-2 text-dark-muted">
        <div className="md:mr-24">
          <SectionMark className="mb-5">
            <p className="text-[24px]">Apa itu CARDS?</p>
          </SectionMark>
          <h2 className="lg:text-[44px] leading-none text-2xl font-bold tracking-wide">
            Memudahkan <span className="text-primary">Lembaga Pendidikan</span> Dalam Mengelola Administrasi dan Keuangan
          </h2>
          <div className="flex flex-col sm:flex-row gap-2 tracking-wider mt-4 items-center justify-center lg:justify-start">
            {/* <Link href="/partner/sekolah" className="btn btn-primary text-white rounded-full w-56 !font-semibold">
              Pelajari Untuk Sekolah
            </Link> */}
            {/* <Link href="/partner/pesantren" className="btn btn-accent text-white rounded-full w-56 !font-semibold">
              Pelajari Untuk Pesantren
            </Link> */}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-4 md:gap-x-2 gap-y-8 mt-8 md:mt-0">
          <div className="flex flex-col gap-4">
            <div className="w-10 h-10 bg-primary rounded-lg text-white flex justify-center items-center">
              <Image src={"/image/icon/custom-computer-icon.png"} width={24} height={24} />
            </div>
            <h5 className="font-semibold mt-1 lg:text-[20px]">Administrasi menjadi digital, mudah digunakan, dapat dilacak, akurat & aman</h5>
          </div>
          <div className="flex flex-col gap-4">
            <div className="w-10 h-10 bg-primary rounded-lg text-white flex justify-center items-center">
              <CgQr className="w-6 h-6" />
            </div>
            <h5 className="font-semibold mt-1 lg:text-[20px]">Menerima transaksi digital untuk tagihan maupun pembayaran di kantin</h5>
          </div>
          <div className="flex flex-col gap-4">
            <div className="w-10 h-10 bg-primary rounded-lg text-white flex justify-center items-center">
              <Image src={"/image/icon/custom-document-icon.png"} width={24} height={24} />
            </div>
            <h5 className="font-semibold mt-1 lg:text-[20px]">Akses informasi akademik mudah dan transparan untuk orang tua maupun sekolah</h5>
          </div>
          <div className="flex flex-col gap-4">
            <div className="w-10 h-10 bg-primary rounded-lg text-white flex justify-center items-center">
              <GoShieldCheck className="w-6 h-6" />
            </div>
            <h5 className="font-semibold mt-1 lg:text-[20px]">Keamanan terjamin dengan penerapan enskripsi data dan pengelolaan hak akses</h5>
          </div>
        </div>
      </section>
      <div className="px-4 lg:my-32 mb-12 lg:mb-0 md:px-8 lg:px-16 flex flex-wrap border-l-primary border-l-4 rounded-xl shadow-lg bg-white">
        <div className="lg:w-[40%] w-full py-3">
          <h2 className="lg:text-[30px] font-bold lg:text-left text-center">
            Dipercaya <span className="text-primary">400+</span> Lembaga pendidikan dan <span className="text-primary">130,000+</span> Siswa
          </h2>
        </div>
        <div className="lg:w-[60%] w-full py-3 rounded-3xl">
          <Swiper
            slidesPerView={1}
            grabCursor={true}
            spaceBetween={30}
            loop={true}
            autoplay={{ delay: 0 }}
            speed={18000}
            direction="horizontal"
            breakpoints={{
              0: {
                slidesPerView: 5,
              },
              768: {
                slidesPerView: 5,
              },
              1024: {
                slidesPerView: 7,
              },
            }}
            modules={[FreeMode, Autoplay]}
            className="h-full flex justify-center items-center"
          >
            {["smpcahayaquran.png", "ihyaulumiddin.png", "alishlah.png", "jalaluddinarrumi.png", "alazhary.png", "tahfizinsanpratama.png", "nazi.png"].map((x, idx) => {
              return (
                <SwiperSlide key={idx}>
                  <div className="w-full h-full flex justify-center items-center">
                    <Image src={`/image/client/${x}`} alt="school image" width={x == "jalaluddinarrumi.png" ? 80 : 130} height={x == "jalaluddinarrumi.png" ? 80 : 130} />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
