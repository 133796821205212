import React from "react";
import Image from "next/image";

function Cashless() {
  return (
    <section className="lg:px-32 w-full px-4 py-12 lg:my-36 my-12">
      <header className="flex flex-col justify-center items-center">
        <h1 className="lg:text-[44px] text-[24px] leading-tight mb-8 font-bold text-center lg:w-[80%] w-full">
          <span className="text-[#0F7996]">Cashless</span> di Kantin Jadi Aman dan Mudah menggunakan <span className="text-[#0F7996]">Uang Saku Digital</span>
        </h1>
        <p className="lg:text-[20px] text-[14px] text-center w-[95%]">
          Transaksi di kantin bisa menggunakan uang saku digital secara non-tunai yang aman dilengkapi dengan PIN transaksi. Semua transaksi terekap dan langsung dimonitoring oleh orang tua. Uang saku santri tersimpan dalam wallet dengan
          device CARDS berupa kartu atau gelang.
        </p>
      </header>

      <div className="flex justify-center items-center flex-wrap mt-12">
        <div className="lg:w-1/3 p-2">
          <div className="rounded-3xl overflow-hidden 3xl:h-[260px] lg:h-[230px] flex justify-center items-center bg-[#6DD3CE]">
            <img src={"/image/kartu-siswa-qr-code.png"} className="lg:w-[400px] 3xl:w-[450px]" />
          </div>
          <p className="font-semibold text-[22px] text-center mt-5">Kartu Siswa Berbasis QR Code</p>
        </div>
        <div className="lg:w-1/3 p-2">
          <div className="rounded-3xl overflow-hidden 3xl:h-[260px] lg:h-[230px] flex justify-center items-center bg-[#6DD3CE]">
            <img src={"/image/kartu-siswa-rfid.png"} className="lg:w-[400px] 3xl:w-[450px]" />
          </div>
          <p className="font-semibold text-[22px] text-center mt-5">Kartu Siswa Berbasis RFID</p>
        </div>
        <div className="lg:w-1/3 p-2">
          <div className="rounded-3xl overflow-hidden 3xl:h-[260px] lg:h-[230px] flex justify-center items-center bg-[#6DD3CE]">
            <img src={"/image/gelang-siswa-rfid.png"} className="lg:w-[400px] 3xl:w-[450px]" />
          </div>
          <p className="font-semibold text-[22px] text-center mt-5">Gelang Siswa Berbasis RFID</p>
        </div>
      </div>
    </section>
  );
}

export default Cashless;
