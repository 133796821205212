export default function getYTTestimonials() {
    let testi = [
        {
            thumbnail: '/image/thumbnail/1.webp',
            link: 'https://www.youtube.com/watch?v=r2iyZBux3iY',
        },
        {
            thumbnail: '/image/thumbnail/2.webp',
            link: 'https://www.youtube.com/watch?v=fp4DGGJc4Ds',
        },
        {
            thumbnail: '/image/thumbnail/3.webp',
            link: 'https://www.youtube.com/watch?v=4MtnHnILWLI',
        },
    ];
    return testi;
}