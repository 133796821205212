import { Disclosure, Transition } from "@headlessui/react";
import SectionMark from "../Misc/SectionMark";
import { FaChevronDown } from "react-icons/fa6";
import getFAQ from "@/lib/GetFAQ";
import { useRef } from "react";

export default function FAQ() {
    let ref = useRef(null);
    let faq = getFAQ();

    return (
        <section className="text-dark-muted pt-64">
            <div className="text-center">
                <SectionMark center>Frequently Asked Questions</SectionMark>
                <h1 className="text-2xl font-bold tracking-wide">Pertanyaan Yang Sering Di Ajukan</h1>
            </div>
            <div className="py-16 mx-auto max-w-screen-xl px-4 text-dark-muted grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-2">
                    {faq.slice(0, 4).map((x, idx) => {
                        return <div key={idx}>
                            <Disclosure>
                            {({ open }) => (
                                <>
                                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-[#EBEFFF] p-4 text-left text-sm font-medium text-opacity-80 hover:bg-[#EBEFFF]/70">
                                    <span>{x.q}</span>
                                    <FaChevronDown
                                        className={`${
                                            open ? 'rotate-180 transform transition duration-300' : ''
                                        } h-3 w-3 text-opacity-80`}
                                    />
                                </Disclosure.Button>
                                <Transition
                                    ref={ref}
                                    show={open}
                                    beforeEnter={() => {
                                    ref.current &&
                                        ref.current.style.setProperty(`max-height`, `${ref.current.scrollHeight}px`);
                                    }}
                                    beforeLeave={() => {
                                    ref.current && ref.current.style.setProperty(`max-height`, `0px`);
                                    }}
                                    className="transition-all duration-300"
                                    enterFrom="transform opacity-0 max-h-[0px]"
                                    enterTo="transform opacity-100"
                                    leaveFrom="transform opacity-50"
                                    leaveTo="transform opacity-0"
                                >
                                    <Disclosure.Panel className="px-4 pt-0 pb-4 text-sm text-opacity-80 bg-[#EBEFFF]">
                                        <div className="divider my-1"></div>
                                        {x.a}
                                    </Disclosure.Panel>
                                </Transition>
                                </>
                            )}
                        </Disclosure>
                        </div>
                    })}
                </div>
                <div className="space-y-2">
                    {faq.slice(4, 8).map((x, idx) => {
                        return <div key={idx}>
                            <Disclosure>
                            {({ open }) => (
                                <>
                                <Disclosure.Button className="flex w-full justify-between rounded-lg bg-[#EBEFFF] p-4 text-left text-sm font-medium text-opacity-80 hover:bg-[#EBEFFF]/70">
                                    <span>{x.q}</span>
                                    <FaChevronDown
                                        className={`${
                                            open ? 'rotate-180 transform transition duration-300' : ''
                                        } h-3 w-3 text-opacity-80`}
                                    />
                                </Disclosure.Button>
                                <Transition
                                    ref={ref}
                                    show={open}
                                    beforeEnter={() => {
                                    ref.current &&
                                        ref.current.style.setProperty(`max-height`, `${ref.current.scrollHeight}px`);
                                    }}
                                    beforeLeave={() => {
                                    ref.current && ref.current.style.setProperty(`max-height`, `0px`);
                                    }}
                                    className="transition-all duration-300"
                                    enterFrom="transform opacity-0 max-h-[0px]"
                                    enterTo="transform opacity-100"
                                    leaveFrom="transform opacity-50"
                                    leaveTo="transform opacity-0"
                                >
                                    <Disclosure.Panel className="px-4 pt-0 pb-4 text-sm text-opacity-80 bg-[#EBEFFF]">
                                        <div className="divider my-1"></div>
                                        {x.a}
                                    </Disclosure.Panel>
                                </Transition>
                                </>
                            )}
                        </Disclosure>
                        </div>
                    })}
                </div>
            </div>
        </section>
    )
}