export default function getFAQ() {
    return [
        {
            q: 'Apakah Cards dapat disesuaikan dengan kebutuhan lembaga pendidikan kami?',
            a: 'Ya, Cards dirancang untuk dapat disesuaikan dengan kebutuhan unik setiap lembaga pendidikan. Kami menyediakan berbagai fitur yang dapat disesuaikan dan dikonfigurasi sesuai dengan kebutuhan Anda.'
        },
        {
            q: 'Apakah Cards menyediakan opsi pembayaran yang fleksibel untuk tagihan sekolah?',
            a: 'Ya, Cards menyediakan opsi pembayaran yang fleksibel untuk tagihan sekolah. Orang tua dapat membayar tagihan secara online menggunakan berbagai metode pembayaran yang kami dukung, termasuk transfer bank, Minimarket, dan dompet digital.'
        },
        {
            q: 'Apakah Cards menyediakan fitur pelaporan keuangan yang komprehensif?',
            a: 'Ya, Cards menyediakan fitur pelaporan keuangan yang komprehensif. Anda dapat menghasilkan berbagai jenis laporan keuangan, seperti laporan pembayaran, laporan tunggakan, neraca, dan laba rugi, untuk memantau kesehatan keuangan lembaga Anda.'
        },
        {
            q: 'Apakah ada biaya tambahan untuk fitur tambahan atau penyesuaian khusus dengan Cards?',
            a: 'Biaya tambahan mungkin berlaku untuk fitur tambahan atau penyesuaian khusus dengan Cards, tergantung pada kebutuhan dan kompleksitas permintaan Anda. Tim kami akan memberikan perkiraan biaya tambahan sebelum implementasi. Namun sejauh ini fitur yang disediakan Cards sudah mencukupi kebutuhan lembaga pendidikan.'
        },
        {
            q: 'Bagaimana proses implementasi Cards di lembaga pendidikan kami?',
            a: 'Proses implementasi Cards melibatkan beberapa tahap, termasuk analisis kebutuhan, konfigurasi sistem, pelatihan staf, dan peluncuran. Tim kami akan membantu Anda melalui setiap langkah proses ini untuk memastikan implementasi yang sukses.'
        },
        {
            q: 'Apakah Cards aman digunakan untuk menyimpan data sensitif siswa dan staf?',
            a: 'Ya, keamanan data adalah prioritas utama kami. Cards menggunakan standar keamanan tinggi dan enkripsi data untuk melindungi informasi sensitif siswa dan staf Anda. Kami juga mematuhi semua regulasi dan kebijakan perlindungan data yang berlaku.'
        },
        {
            q: 'Bagaimana cara memperoleh demo produk Cards?',
            a: 'Anda dapat meminta demo produk Cards dengan menghubungi kami melalui formulir kontak di situs web kami atau dengan mengklik tombol "Request Demo" di halaman kami. Tim kami akan mengatur jadwal demo sesuai dengan ketersediaan Anda. Untuk selanjutnya anda akan dibebankan biaya langganan persiswa pertahun.'
        },
        {
            q: 'Apakah ada dukungan pelanggan yang tersedia setelah layanan Cards diimplementasikan?',
            a: 'Ya, kami menyediakan dukungan pelanggan yang komprehensif setelah implementasi Cards. Tim dukungan kami siap membantu Anda dengan pertanyaan, permintaan bantuan, atau masalah teknis yang mungkin Anda hadapi.'
        },
    ]
}